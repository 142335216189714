import bannerImage1 from '../assets/bannerImages/bannerImage1.jpg';
import bannerImage2 from '../assets/bannerImages/bannerImage2.jpg';
import bannerImage3 from '../assets/bannerImages/bannerImage3.jpg';
import bannerImage4 from '../assets/bannerImages/bannerImage4.jpg';
import bannerImage5 from '../assets/bannerImages/bannerImage5.jpg';
const bannerImages = [
  {
    id: 0,
    image: bannerImage1,
    title: "bannerImage1",
  },
  {
    id: 1,
    image: bannerImage2,
    title: "bannerImage2",
  },
  {
    id: 2,
    image: bannerImage3,
    title: "bannerImage3",
  },
  {
    id: 3,
    image: bannerImage4,
    title: "bannerImage4",
  },
  {
    id: 4,
    image: bannerImage5,
    title: "bannerImage5",
  }
]

export default bannerImages;